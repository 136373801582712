import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  baseUrl = environment.apiUrl;

  public readonly placeholderImage = 'assets/images/image-placeholder.dark-min.png';
  public readonly errorImage = 'assets/images/error-placeholder2.dark-min.png';
  public readonly noPersonImage = 'assets/images/error-person-missing.dark.min.png';
  public readonly resetCoverImage = 'assets/images/image-reset-cover-min.png';




  getLibraryCoverImage(libraryId: number) {
    return `${this.baseUrl}image/library?libraryId=${libraryId}`;
  }

  getMovieCoverImage(movieCode: string) {
    return `${this.baseUrl}image/movie?movieCode=${movieCode}`;
  }

  getPersonCoverImage(personId: number) {
    return `${this.baseUrl}image/person?personId=${personId}`;
  }

  /**
   * Returns the entity type from a cover image url. Undefined if not applicable
   * @param url
   * @returns
   */
  getEntityTypeFromUrl(url: string) {
    if (url.indexOf('?') < 0) return undefined;
    const part = url.split('?')[0];
    return part.substring(part.lastIndexOf('/') + 1, part.length);
  }

  /**
   * Used to refresh an existing loaded image (lazysizes). If random already attached, will append another number onto it.
   * @param url Existing request url from ImageService only
   * @returns Url with a random parameter attached
   */
  randomize(url: string) {
    const r = Math.round(Math.random() * 100 + 1);
    if (url.indexOf('&random') >= 0) {
      return url + 1;
    }
    return url + '&random=' + r;
  }

}
